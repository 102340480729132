import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import style from "./subscribe-job.module.css"
import { states } from "./states"

const SubscribeJob = ({ show, setShow }) => {
  const [categories, setCategories] = useState([])
  // const [states, setStates] = useState([])
  // const [cities, setCities] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedCities] = useState([])
  const [selectedStates, setSelectedStates] = useState([])
  const [email, setEmail] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")

  const apiURL =
    (process.env.GATSBY_API_URL || "http://localhost:3000/production") +
    `/filters`

  const changeCategories = (event, category) => {
    let isChecked = event.target.checked

    if (isChecked) {
      setSelectedCategories([...selectedCategories, category.name])
    } else {
    }
  }

  // const changeCities = (event, city) => {
  //   let isChecked = event.target.checked

  //   if (isChecked) {
  //     setSelectedCities([...selectedCities, city])
  //   } else {
  //   }
  // }

  const changeStates = (event, state) => {
    let isChecked = event.target.checked
    if (isChecked) {
      setSelectedStates([...selectedStates, state])
    } else {
    }
  }

  useEffect(() => {
    // fetch(`${apiURL}?filter=state`)
    //     .then( res => res.json())
    //     .then(result => {
    //         setStates(result.data.map(d => d?.address?.state));
    //     })

    // fetch(`${apiURL}?filter=city`)
    //     .then( res => res.json())
    //     .then(result => {
    //         setCities(result.data.map(d => d?.address?.city));
    //     })
    fetch(`${apiURL}?filter=categories`)
      .then(res => res.json())
      .then(result => {
        setCategories(result.data.map(d => d?.publishedCategory))
      })
  }, [])

  const handleSubmit = async e => {
    e.preventDefault()
    let subscriptionObj = {
      categories: selectedCategories,
      cities: selectedCities,
      states: selectedStates,
      email: email,
      firstName: firstName,
      lastName: lastName,
    }

    fetch(
      (process.env.GATSBY_API_URL || "http://localhost:3000/production") +
        `/job-subscribers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subscriptionObj),
      }
    ).then(() => {
      setShow(false)
    })
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName={style.modal60w}
    >
      <Modal.Header closeButton>
        <Modal.Title>Subscribe to Job Alerts</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="form-group mt-0">
            <input
              type="email"
              name="email"
              onChange={event => setEmail(event.target.value)}
              className="form-control"
              required={true}
            />
            <label
              htmlFor="email"
              className={email.length ? style.activeLabel : style.normalLabel}
            >
              Email
            </label>
          </div>
          <div className={style.rowContainer}>
            <div
              className="form-group mt-3 mb-0"
              style={{ width: "100%", marginRight: 16 }}
            >
              <input
                type="text"
                name="firstName"
                onChange={event => setFirstName(event.target.value)}
                className="form-control w-100"
                required={true}
              />
              <label
                htmlFor="firstName"
                className={
                  firstName.length ? style.activeLabel : style.normalLabel
                }
              >
                First Name
              </label>
            </div>
            <div
              className="form-group mt-3 mb-0"
              style={{ width: "100%", marginLeft: 16 }}
            >
              <input
                type="text"
                name="lastName"
                onChange={event => setLastName(event.target.value)}
                className="form-control w-100"
                required={true}
              />
              <label
                htmlFor="lastName"
                className={
                  lastName.length ? style.activeLabel : style.normalLabel
                }
              >
                Last Name
              </label>
            </div>
          </div>

          <h5 className="mt-4">Categories</h5>
          <div className={style.formGroup}>
            <div className="row">
              {categories.map((category, index) => (
                <div className="col-md-6" key={index}>
                  <input
                    type="checkbox"
                    onChange={$event => changeCategories($event, category)}
                  ></input>
                  <span className={style.checkboxLabel}>{category.name}</span>
                </div>
              ))}
            </div>
          </div>
          {/* <h5 className="mt-4">Cities</h5>
                    <div className={style.formGroup}>
                        <div className="row">
                            {
                                cities.map( (city, index) => (
                                    <div className="col-6" key={index}>
                                        <input type="checkbox" onChange={($event) => changeCities($event, city)}></input>
                                        <span className={style.checkboxLabel}>{city}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div> */}
          <h5 className="mt-4">State</h5>
          <div className={style.formGroup}>
            <div className="row">
              {states.map((state, index) => (
                <div className="col-md-6" key={index}>
                  <input
                    type="checkbox"
                    onChange={$event => changeStates($event, state)}
                  ></input>
                  <span className={style.checkboxLabel}>{state}</span>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {/* <Button variant="secondary" onClick={() => setShow(false)}>Close</Button> */}
          <Button variant="primary" type="submit">
            GET JOB ALERTS
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default SubscribeJob
