import React, { Fragment, useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"
import moment from "moment"
import marked from "marked"
import { Link } from "gatsby"
// Assets
import map from "../../images/icons/map-marker.png"
import work from "../../images/icons/work.png"
import date from "../../images/icons/date-range.png"
import puzzle from "../../images/icons/puzzle.png"
import search from "../../images/icons/search.png"
import peer from "../../images/icons/peer.png"
import SendApplication from "./send-application"
import Loading from "../loading"
import loading from "../../images/loading.gif"

const Profile = ({ dataId }) => {
  const [job, setJob] = useState([])
  const [load, setLoad] = useState(true)
  const [showSendApplicationModal, setShowSendApplicationModal] = useState(
    false
  )

  useEffect(() => {
    fetch(
      (process.env.GATSBY_API_URL || "http://localhost:3000/production") +
        `/jobs/${dataId}`
    )
      .then(res => res.json())
      .then(result => {
        setJob(result.data[0])
        setLoad(false)
      })
      .catch(err => console.log("error", err))
  }, [dataId])
  // console.log("jobsjobs", job)

  return (
    <Fragment>
      <div className="page-headline">
        <Container>
          <div className="section-heading text-center">
            <h1 className="font-weight-bold text-uppercase flair">
              {job.title}
            </h1>
          </div>
        </Container>
      </div>

      <div className="page-content pt-4 pb-0">
        <Container className="pb-100">
          {!load ? (
            <Col md={10} className="m-auto">
              <div className="job-board">
                <div className="job-info">
                  <Row>
                    <Col md={4} sm={4} xs={4} className="text-sm-center">
                      <Row className="py-4">
                        <Col md={4} className="text-md-right">
                          <img className="img-fluid" src={map} alt="map" />
                        </Col>
                        <Col md={8} className="text-center text-md-left">
                          <p className="text-grey mb-1 text-uppercase">
                            Location
                          </p>
                          <h4 className="p-0 font-weight-normal mb-0">
                            {job?.address?.city}, {job?.address?.state}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      md={4}
                      sm={4}
                      xs={4}
                      className="border-x-grey text-sm-center"
                    >
                      <Row className="py-4">
                        <Col md={4} className="text-md-right">
                          <img className="img-fluid" src={work} alt="work" />
                        </Col>
                        <Col md={8} className="text-center text-md-left">
                          <p className="text-grey mb-1 text-uppercase">
                            Employment Type
                          </p>
                          <h4 className="p-0 font-weight-normal mb-0">
                            {job?.employmentType}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} sm={4} xs={4} className="text-sm-center">
                      <Row className="py-4">
                        <Col md={4} className="text-md-right">
                          <img className="img-fluid" src={date} alt="date" />
                        </Col>
                        <Col md={8} className="text-center text-md-left">
                          <p className="text-grey mb-1 text-uppercase">
                            Date Posted
                          </p>
                          <h4 className="p-0 font-weight-normal mb-0">
                            {" "}
                            {moment(job.dateLastPublished).format(
                              "MMM DD, YYYY"
                            )}
                          </h4>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
                {job.publicDescription && (
                  <>
                    <div
                      className="job-summary text-left"
                      dangerouslySetInnerHTML={{
                        __html: marked(job.publicDescription),
                      }}
                    />

                    <div className="text-center pb-5">
                      <button
                        className="btn common-btn"
                        onClick={() => {
                          setShowSendApplicationModal(true)
                        }}
                      >
                        Apply Now
                      </button>
                      <SendApplication
                        jobId={dataId}
                        show={showSendApplicationModal}
                        setShow={setShowSendApplicationModal}
                      ></SendApplication>
                    </div>
                  </>
                )}
              </div>
            </Col>
          ) : (
            <div className="text-center">
              <img src={loading} className="img-fluid" alt="loading" />
            </div>
            // <Loading />
          )}
        </Container>
        <div className="bg-blue text-center py-100">
          <Container>
            <Row>
              <Col md={4} className="mb-4">
                <div className="lookPeer">
                  <img className="img-fluid" src={puzzle} alt="puzzle" />
                  <h4>Looking for IT Talent?</h4>
                  <p>
                    Our PeerNetwork model and experienced recruiters allows us
                    to offer a wide range of technical and non-technical talent
                    for our clients.
                  </p>
                  <Link to="/">Learn More</Link>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div className="lookPeer">
                  <img className="img-fluid" src={search} alt="search" />
                  <h4>Search for Jobs</h4>
                  <p>Check out our current opportunities!</p>
                  <Link to="/">Learn More</Link>
                </div>
              </Col>
              <Col md={4} className="mb-4">
                <div className="lookPeer">
                  <img className="img-fluid" src={peer} alt="peer" />
                  <h4>PeerNetwork</h4>
                  <p>
                    Find out more about our unique PeerNetwork of Companies that
                    enables us to provide the best possible service to our
                    candidates and clients.
                  </p>
                  <Link to="/">Learn More</Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Fragment>
  )
}

export default Profile
