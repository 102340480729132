import React, { useState, useEffect, useRef, Fragment } from "react"
import Layout from "../components/layout"
// import marked from "marked"
import { Container, Row, Col } from "reactstrap"
import moment from "moment"
import _ from "lodash"
import usePagination from "react-use-paging"
import { Link } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params"
import loading from "../images/loading.gif"
import Profile from "../components/job/profile"
import SubscribeJob from "../components/job/subscribe-job"
import SEO from "../components/seo"
import Loading from "../components/loading"

const slugify = require("slugify")

const JobListPage = () => {
  const [data, setData] = useState([])
  const [load, setLoad] = useState(true)
  const [categories, setCategories] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [showSubscribeModal, setShowSubscribeModal] = useState(false)
  const [filters, setFilters] = useState({
    categories: "",
    states: "",
    cities: "",
  })
  const apiURL =
    (process.env.GATSBY_API_URL || "http://localhost:3000/production") + `/jobs`

  // GET JUST THE `total` key value
  useEffect(() => {
    fetch(apiURL)
      .then(res => res.json())
      .then(result => {
        // console.log("totalR", result.total)
        // console.log("total", result.data)
        fetch(`${apiURL}?start=0&count=${result.total}`)
          .then(res => res.json())
          .then(result => {
            console.log("resultAll", result.data)
            setData(result.data)
            setCategories(result.data.map(d => d?.publishedCategory))
            setStates(result.data.map(d => d?.address?.state))
            setCities(result.data.map(d => d?.address?.city))
            setLoad(false)
          })
          .catch(err => console.log("error", err))
      })
      .catch(err => console.log("error", err))
  }, [])

  // Select Filters
  useEffect(() => {
    const filtering = (myCategories, myStates, myCities) => {
      console.log("filter1", myCategories, myStates, myCities)
      const myFilter = `${myCategories &&
        `&categories=${myCategories}`}${myStates &&
        `&states=${myStates}`}${myCities && `&cities=${myCities}`}`
      console.log("myFilter", myFilter)
      fetch(`${apiURL}?${myFilter}`)
        .then(res => res.json())
        .then(result => {
          setData(result.data)
        })
        .catch(err => console.log("error", err))
    }

    filtering(filters.categories, filters.states, filters.cities)
  }, [filters])

  // Submit
  const searchKey = useRef(null)
  const onButtonClick = () => {
    searchKey.current.focus()
    // console.log("searchKey.current.value", searchKey?.current?.value)
    fetch(`${apiURL}?searchKey=${searchKey?.current?.value}`)
      .then(res => res.json())
      .then(result => setData(result.data))
      .catch(err => console.log("error", err))
  }
  const subscribeJobAlert = () => {
    setShowSubscribeModal(true)
  }

  // pagination
  const { items, pages, setCurrentPage, currentPage } = usePagination(data, {
    resultPerPage: 8,
    alwaysVisible: false, // First page won't show if all results are in one page.
  })

  const [dataId] = useQueryParam("id", StringParam)
  console.log("🚀 ~ file: jobs.js ~ line 84 ~ JobListPage ~ dataId", dataId)

  return (
    <Layout>
      <SEO title="Search for Jobs" />
      {!dataId ? (
        <Fragment>
          <div className="page-headline">
            <div className="container">
              <div className="section-heading text-center">
                <h1 className="font-weight-bold text-uppercase flair">
                  search for jobs
                </h1>
              </div>
            </div>
          </div>

          <div className="page-content py-4">
            <Container>
              {!load ? (
                <Fragment>
                  <div className="row">
                    <div className="search-job col-10 mx-auto">
                      <div className="position-relative">
                        <label htmlFor="search" className="label-search">
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </label>
                        <input
                          type="text"
                          name="search"
                          id="search"
                          ref={searchKey}
                          className="w-100"
                          placeholder="Search keywords"
                        />
                        <button className="common-btn" onClick={onButtonClick}>
                          SEARCH
                        </button>
                      </div>
                      {searchKey?.current?.value && (
                        <p className="mt-3">
                          Search: {searchKey.current.value}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 mx-auto">
                      <button
                        className="common-btn"
                        onClick={subscribeJobAlert}
                      >
                        SUBSCRIBE TO JOB ALERTS
                      </button>
                    </div>
                  </div>
                  <SubscribeJob
                    show={showSubscribeModal}
                    setShow={setShowSubscribeModal}
                  ></SubscribeJob>
                  <div className="row">
                    <div className="filters col-10 mx-auto mt-5">
                      <Row>
                        <Col md={4}>
                          <label htmlFor="category" className="d-block">
                            Filter by Category:
                          </label>
                          <select
                            className="w-100"
                            id="category"
                            onChange={e =>
                              setFilters({
                                categories: e.target.value,
                                states: filters.states,
                                cities: filters.cities,
                              })
                            }
                          >
                            <option value="">Select Category</option>
                            {categories.length &&
                              _.uniqBy(categories, "id").map(cat => (
                                <option value={cat.id} key={cat.id}>
                                  {cat.name}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md={4}>
                          <label htmlFor="states" className="d-block">
                            State:
                          </label>
                          <select
                            className="w-100"
                            id="states"
                            onChange={e =>
                              setFilters({
                                categories: filters.categories,
                                states: e.target.value,
                                cities: filters.cities,
                              })
                            }
                          >
                            <option value="">Select State</option>
                            {states.length &&
                              _.uniqBy(states).map(state => (
                                <option value={state} key={state}>
                                  {state}
                                </option>
                              ))}
                          </select>
                        </Col>
                        <Col md={4}>
                          <label htmlFor="city" className="d-block">
                            City:
                          </label>
                          <select
                            className="w-100"
                            id="city"
                            onChange={e =>
                              setFilters({
                                categories: filters.categories,
                                states: filters.states,
                                cities: e.target.value,
                              })
                            }
                          >
                            <option value="">Select City</option>
                            {cities.length &&
                              _.uniqBy(cities).map(city => (
                                <option value={city} key={city}>
                                  {city}
                                </option>
                              ))}
                          </select>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="row">
                    <div className="results col-10 mx-auto">
                      {!load && data.length ? (
                        <h3>Open Positions ({data.length})</h3>
                      ) : (
                        <h3>No result</h3>
                      )}

                      {items.length && (
                        <Fragment>
                          {items.map(res => (
                            <div className="result-wrapper" key={res.id}>
                              <Link
                                className="title"
                                to={`/jobs/${slugify(res.title.toLowerCase(), {
                                  remove: /[*+~.()'"!:@?]/g,
                                })}`}
                              >
                                {res.title}
                              </Link>
                              <p>{res.publishedCategory?.name}</p>

                              <div
                                className="desc"
                                dangerouslySetInnerHTML={{
                                  __html: res.publicDescription,
                                }}
                              ></div>
                              <ul className="list-unstyled mt-3">
                                <li className="d-inline mr-2">
                                  <i
                                    className="fa fa-map-marker"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {res?.address?.city}, {res?.address?.state}
                                </li>
                                <li className="d-inline mr-2">
                                  {res.employmentType}
                                </li>
                                <li className="d-inline mr-2">
                                  {moment(res.dateLastPublished).format(
                                    "MMM DD, YYYY"
                                  )}
                                </li>
                              </ul>
                            </div>
                          ))}
                          <div className="py-5 text-center">
                            {currentPage !== 1 ? (
                              <button
                                // You add style to the current page by cheking the current page you are on.
                                className={`paginate-btn `}
                                onClick={() => setCurrentPage(currentPage - 1)}
                              >
                                &lt;
                              </button>
                            ) : null}
                            {pages.map(page => (
                              <button
                                key={page}
                                // You add style to the current page by cheking the current page you are on.
                                className={`paginate-btn ${
                                  page === currentPage ? "active" : ""
                                }`}
                                onClick={() => setCurrentPage(page)}
                              >
                                {page}
                              </button>
                            ))}
                            {currentPage + 1 <= pages.length ? (
                              <button
                                // You add style to the current page by cheking the current page you are on.
                                className={`paginate-btn `}
                                onClick={() => setCurrentPage(currentPage + 1)}
                              >
                                &gt;
                              </button>
                            ) : null}
                            {currentPage !== pages.length ? (
                              <button
                                // You add style to the current page by cheking the current page you are on.
                                className={`paginate-btn ${pages.length === 0 &&
                                  "d-none"}`}
                                onClick={() => setCurrentPage(pages.length)}
                              >
                                {"Last >"}
                              </button>
                            ) : null}
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="text-center">
                  <img src={loading} className="img-fluid" alt="loading" />
                </div>
              )}
            </Container>
          </div>
        </Fragment>
      ) : (
        <Profile dataId={dataId} />
      )}
    </Layout>
  )
}

export default JobListPage
